<template>
    <el-dialog title="部门详情" :visible.sync="departInfoDetailVisible" :close-on-click-modal="false" width="900px" append-to-body :before-close="handleClose">
        <el-form :model="ruleForm" ref="ruleForm" label-width="130px" class="demo-ruleForm">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门名称：" prop="name">
                        <span>{{ detailData.departmentName || '--' }}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="部门全称：" prop="name">
                        <span>{{ detailData.departmentFullName || '--' }}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门电话：" prop="name">
                        <span>{{ detailData.departmentPhone || '--' }}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="上级部门：" prop="name">
                        <span>{{ detailData.parentName || '--' }}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="部门排序：" prop="name">
                        <span>{{ detailData.departmentSort || '--' }}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="部门地址：" prop="name">
                        <span>{{ detailData.departmentAddress || '--' }}</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-form-item label="部门简介：" prop="name">
                        <span>{{ detailData.description || '--' }}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer">
            <el-button @click="handleClose">取 消</el-button>
            <!--      <el-button type="primary" @click="handleClose">确 定</el-button>-->
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'departInfoDetailDilog',
    props: {
        departInfoDetailVisible: {
            type: Boolean,
            default: false
        },
        detailData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    watch: {
        detailData: {
            deep: true,
            handler(val) {
                this.ruleForm = val
            }
        }
    },
    data() {
        return {
            ruleForm: {}
        }
    },
    methods: {
        handleClose() {
            this.$emit('update:departInfoDetailVisible', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.w230 {
    width: 230px;
}
.info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
}
</style>
